import { Component, Prop, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import { TableOptions } from "@/api/request";
import { SearchRequest, SortRequest } from "@/api/ticket/request";
import TicketSearch, { ListItem } from "@/store/ticket/search";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import TicketModify from "@/store/ticket/modify";
import { DeleteRequest } from "@/api/coupon/request";
import Sortable, { MoveEvent } from "sortablejs";
import TicketPreviewGet from "@/store/ticket-preview/get";
import { GetRequest } from "@/api/ticket-preview/request";
import WindowOpen from "@/utils/window-open";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDataTable,
    UIDatePicker,
    UIDialogDelete,
    UlFileRegister
  }
})
export default class List extends Vue {
  @Prop({ required: true })
  shopId!: number;

  shopName = "";

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "デジタルチケット";
  headingSub = "Digital ticket";
  breadCrumbs = [
    { text: "アピール", disabled: true },
    { text: "デジタルチケット", disabled: true },
    { text: "店舗一覧", to: { name: "ticket" } },
    { text: "デジタルチケット一覧", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "", value: "reorder", sortable: false },
    { text: "チケットタイトル", value: "title", sortable: false },
    { text: "チケットタイプ", value: "typeName", sortable: false },
    { text: "チケット有効期間", value: "expiration", sortable: false },
    { text: "状態", value: "status", sortable: false },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    },
    {
      label: "プレビュー",
      text: "",
      value: "action",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------
  // 削除ダイアログの表示有無
  showDialog = false;

  // 削除されるタイトル
  deletingTitle = "";

  // 削除されるメッセージID
  deletingId = 0 as number;

  // ローディングステータス
  isLoading = false;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {} as TableOptions;

  // ------------

  /**
   * 新規作成権限の有無
   */
  get writeFlg() {
    return TicketSearch.getWriteFlg;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return TicketSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return TicketSearch.getTotalCount;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    // 店舗名を表示
    if (this.$route.query.shopName) {
      this.shopName = String(this.$route.query.shopName);
    }
    await TicketSearch.restore();
    await this.search();

    this.$nextTick(() => {
      const _self = this;

      if (this.writeFlg) {
        const mainTable = document.querySelector(
          ".v-data-table tbody"
        ) as HTMLElement;
        Sortable.create(mainTable, {
          onEnd({ newIndex, oldIndex }) {
            const rowSelected = _self.tableItems.splice(oldIndex as number, 1)[0];
            _self.tableItems.splice(newIndex as number, 0, rowSelected);
            _self.sort().then();
          },
          onMove(evt: MoveEvent, originalEvent: Event): boolean {
            return (
              !evt.dragged.querySelector(".not-sortable") &&
              !evt.related.querySelector(".not-sortable")
            );
          }
        });
      }

    });
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await TicketSearch.clearResponse();
    await TicketModify.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async actionClickCallback(item: ListItem) {
    await TicketPreviewGet.get({
      id: item.id,
      shop_id: this.shopId
    } as GetRequest);
    if (TicketPreviewGet.isSuccess) {
      WindowOpen.preview(TicketPreviewGet.getPreviewUrl);
    } else {
      await Flash.setErrorNow({
        message: TicketPreviewGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * テーブル行の修正ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async editClickCallback(item: ListItem) {
    await this.$router.push({
      name: "ticket-edit",
      params: { id: item.id.toString() },
      query: { shopId: this.shopId.toString(), shopName: this.shopName }
    });
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: ListItem) {
    this.showDialog = true;
    this.deletingId = item.id;
    this.deletingTitle = item.title;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDialog = false;
    if (this.deletingId <= 0) {
      return;
    }

    this.isLoading = true;
    await TicketModify.deleteOne({
      id: this.deletingId
    } as DeleteRequest);
    if (TicketModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "クーポンを削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: TicketModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  async goToNew() {
    await this.$router.push({
      name: "ticket-new",
      query: { shopId: this.shopId.toString(), shopName: this.shopName }
    });
  }

  async goToSetting() {
    await this.$router.push({
      name: "ticket-setting",
      params: { shopId: String(this.shopId) },
      query: { shopName: this.shopName }
    });
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    let request = this.createRequest();
    await TicketSearch.search(request);
    if (!TicketSearch.isSuccess) {
      await Flash.setErrorNow({
        message: TicketSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 並び替え処理
   */
  async sort() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    await TicketModify.sort(this.createSortRequest());
    if (!TicketModify.isSuccess) {
      await Flash.setErrorNow({
        message: TicketModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = {} as SearchRequest;
    request.shopId = this.shopId;

    return request;
  }

  /**
   * dataから並び替え用のリクエストを作成する
   */
  private createSortRequest() {
    const request = { shopId: Number(this.shopId) } as SortRequest;
    request.ids = this.tableItems.map(i => i.id);
    return request;
  }
}
