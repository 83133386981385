import service from "@/api/service";
import {
  SearchRequest,
  DetailRequest,
  SortRequest,
  RegisterRequest,
  DeleteRequest
} from "@/api/ticket/request";
import {
  SearchResponse,
  DetailResponse,
  RegisterResponse
} from "@/api/ticket/response";
import { CommonResponse } from "@/api/response";

/**
 * デジタルチケット一覧画面APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-ticket", searchRequest);
  return response.data as SearchResponse;
}

/**
 * デジタルチケット詳細情報登録更新APIをコールします。
 *
 * @param detailRequest 検索のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/detail-ticket", detailRequest);
  return response.data as DetailResponse;
}

/**
 * デジタルチケットソート順更新APIをコールします。
 *
 * @param sortRequest
 * @return CommonResponse
 */
export async function sort(sortRequest: SortRequest) {
  const response = await service.post("/sort-ticket", sortRequest);
  return response.data as CommonResponse;
}

/**
 * デジタルチケット詳細情報登録更新APIをコールします。
 *
 * @param registerRequest 検索のリクエストボディ
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-ticket", registerRequest);
  return response.data as RegisterResponse;
}

/**
 * デジタルチケット削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-ticket", deleteRequest);
  return response.data as CommonResponse;
}
