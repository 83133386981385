import service from "@/api/service";
import { GetResponse } from "@/api/ticket-preview/response";
import { GetRequest } from "@/api/ticket-preview/request";

/**
 * デジタルチケットプレビュー取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/ticket-preview", getRequest);
  return response.data as GetResponse;
}
